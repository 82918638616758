import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import {loaderContext} from "../../loaderContext";
import {userContext} from "../../userContext";
import config from "../../config";
import { useNavigate } from "react-router-dom";

export default function DeleteAccount(){
  const [input,setInput] = React.useState("");
  const {user} = React.useContext(userContext);
  const {showLoader} = React.useContext(loaderContext);
  const navigate = useNavigate();

  const updateText = (e) => {
    setInput(e.target.value.toLowerCase().trim());
  }

  const deleteAccount = (e) => {
    if(input == "permanently delete"){
      showLoader("DELETING YOUR ACCOUNT");

      var formData = new FormData;
      formData.append('id',user.id);
      formData.append('token',user.token);
      formData.append('confirm',input);
      fetch(config.deleteAccount.API_ENDOINT,{
        method:"POST",
        headers:{
          'Accept':'application/json'
        },
        body:formData
      }).then(res=>res.json())
      .then(data=>{
        showLoader(false);
        navigate("/");
        window.hh2020Login.logout();
        alert(data.message);

      });


    }
    else{
      alert("You must type PERMANENTLY DELETE in the textbox to continue.");
    }

  }

  return (<>
    <h2>Delete My Account</h2>

    <big className="intro">
      Please note, by deleting your account all comments, job postings, business listings, obituary submissions, and event listings made by you will also be deleted.   <b>This is irreversible</b>.
    </big>

    <div className="deletePrompt">
      To continue deleting your account, type
      <pre>PERMANENTLY DELETE</pre>
      in the box below:
      <input type="text" onChange={updateText}/>
      <button onClick={deleteAccount} className="button red">DELETE MY ACCOUNT</button>
    </div>


  </>);
}
