import React from "react";
import CommentsList from "./CommentsList";
import {userContext} from "../../userContext";
import {loaderContext} from "../../loaderContext";
import Popup from "../../shared/Popup";
import config from "../../config";
import { useNavigate } from "react-router-dom"


export default function Comments(){
  const {user} = React.useContext(userContext);
  const {showLoader} = React.useContext(loaderContext);
  const navigate = useNavigate();

  const [confirm,showConfirm] = React.useState(false);
  const [count,setCount] = React.useState(0);

  const deleteAll = () => {
    var formData = new FormData;
    formData.append('id',user.id);
    formData.append('token',user.token);
    showLoader("Deleting Comments");
    showConfirm(false);
    fetch(config.comments.API_DOMAIN+"api/comments/admin/deleteAll",{
      method:"POST",
      headers:{
        'Accept':'application/json'
      },
      body:formData
    }).then(res=>res.json())
    .then(data=>{
      showLoader(false);
      alert(data.message);
      navigate("/");
    });
  };

  return (<div className="Comments">
      <Popup
       show={confirm}
       hide={showConfirm}
       style={{textAlign:"center"}}
       buttons={[
         {
           label:'Cancel',
           onClick:()=>showConfirm(false),
           className:"cancel"
         },
         {
           label:'Confirm',
           className:"danger",
           onClick:()=>deleteAll(),

         },

       ]}
      >
        <h2 style={{textAlign:"center"}}>Are you sure?</h2>
        Click "Confirm" to delete all comments.   This action is permanent, and can not be undone.
      </Popup>
      <h2>My Comments</h2>
      {count > 0 && <button className="buttonText red" style={{marginLeft:"auto",marginRight:"auto"}} onClick={()=>showConfirm(true)}>Delete All Comments</button>}
      <CommentsList setCount={setCount} limit={30} filter={{authorid:user.id}}/>
    </div>);
}
