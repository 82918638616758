import React from "react";
import {NavLink} from "react-router-dom";
import {userContext} from "../userContext"

export default function Home(){
  const {user,userHash} = React.useContext(userContext);
  return (<ul className="menu">
      <li><NavLink to="/">Overview</NavLink></li>
      <li><NavLink to="/comments">My Comments</NavLink></li>
      <li><NavLink to="/directory">Business Listings</NavLink></li>
      <li><NavLink to="/jobs">Job Listings</NavLink></li>
      <li><a href="#" onClick={()=>{ window.open("https://hubhawaii.com/events/my/"+userHash()); }}>Event Listings</a></li>
      <li><a href="#" onClick={()=>window.hh2020Profile.showProfile(user.id)}>My Profile</a></li>
      <li><NavLink to="/delete">Delete Account</NavLink></li>


    </ul>);
}
