import logo from './logo.svg';
import './style/base.scss';
import {UserContextProvider} from "./userContext";
import {LoaderContextProvider} from "./loaderContext";
import {BrowserRouter as Router, Routes, Route,useLocation} from "react-router-dom";
import Header from "./screens/Header";
import Footer from "./screens/Footer";
import DataRoutes from "./DataRoutes";

function App() {



  return (<>
    <Header/>
    <div className="container main">
    <Router>
      <LoaderContextProvider>
        <UserContextProvider admin={false}>
          <DataRoutes/>
        </UserContextProvider>
      </LoaderContextProvider>
    </Router>
    </div>
    <Footer/>
  </>);
}

export default App;
