import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import config from "../../config";
import Popup from "../../shared/Popup";
import Form from "../../shared/Form";
import {ajaxPOST,ajaxGET} from "../../ajax";


export default function ObituaryList({type,slug,title,limit}){

  const [showPopup,setShowPopup] = React.useState(false);
  const [actionSuccess,setActionSuccess] = React.useState(false);
  const [actionLoading,setActionLoading] = React.useState(false);
  const [focusItem,setFocusItem] = React.useState(false);
  const [focusItemDetails,setFocusItemDetails] = React.useState(false);
  const [optionItem,setOptionItem] = React.useState(false);
  const [updateData,setUpdateData] = React.useState(false);
  const [showForm,setShowForm] = React.useState(false);

  const hidePopup = () => {
    setShowPopup(false);
    setActionLoading(false);
    setActionSuccess(false);
    setOptionItem(false);
  };

  const showOptions = (item) => {
    setOptionItem(item);
    setShowPopup(true);

  };


  const rowAction = async (action,data,loading) => {
    setActionLoading(loading);
    var formData = new FormData;

    var result = await ajaxPOST(config.obituaries.API_DOMAIN+"api/"+action,data);

    setActionSuccess(result.message);
    if(action == "delete" || action == "approve"){
      setUpdateData({
        key:'deathrecordid',
        keyValue:data.deathrecordid,
        newData:"delete"
      });
    }
  };

  React.useEffect(async ()=>{
    if(focusItem === false) return;
    var result = await ajaxGET(config.obituaries.API_DOMAIN+"api/get",{'deathrecordid':focusItem.deathrecordid,'slug':slug});
    if(result){
      setFocusItemDetails(result);
    }
  },[focusItem]);

  React.useEffect(async ()=>{
    if(optionItem === false) return;
    var result = await ajaxGET(config.obituaries.API_DOMAIN+"api/get",{'deathrecordid':optionItem.deathrecordid,'slug':slug});
    if(result){
      setOptionItem({...result,details:result.details.replace(/<p\>/g,"\n\n")});
    }
  },[showForm]);


  return (<>


    <Popup
     show={showPopup}
     hide={hidePopup}>
     {actionLoading ?
       (<>
         {actionSuccess ? (<>
           <center>{actionSuccess}</center>
           <ul className="optionsList">
            <li onClick={hidePopup}>OK</li>
           </ul>
         </>) : (<center>{actionLoading}</center>)}
       </>) : (<>
         <b style={{display:"block",textAlign:"center",paddingBottom:10}}>{optionItem.title}</b>
        <ul className="optionsList">
          {(focusItem.approved !== "true") && (<li onClick={()=>rowAction("approve",{'deathrecordid':optionItem.deathrecordid,'slug':slug},"Approving Obituary")} className="">Approve Obituary</li>)}
          <li onClick={()=>rowAction("delete",{'deathrecordid':optionItem.deathrecordid,'slug':slug},"Deleting Obituary")} className="">Delete Obituary</li>
          <li onClick={()=>setShowForm(true)}>Edit Obituary</li>

          <li onClick={hidePopup} className="cancel">Cancel</li>
         </ul>
       </>)}
    </Popup>

    <Popup
      show={focusItem}
      hide={()=>{ setFocusItem(false); setFocusItemDetails(false); }}
    >
      <div className="details">
        {focusItem.image && (<img src={focusItem.image}/>)}
        <h2>{focusItem.title}</h2>
        <i>{helpers.date(focusItem.birthdate)} - {helpers.date(focusItem.deathdate)}</i>

        <p dangerouslySetInnerHTML={{__html:focusItemDetails.details}}></p>

        <center>
          {focusItem.approved !== "true" && <button className="button2" onClick={()=>{ showOptions(focusItem); setFocusItem(false); rowAction("approve",{'deathrecordid':focusItem.deathrecordid,'slug':slug},"Approving Obituary"); }}>Approve</button>}
          <button className="button2 red" onClick={()=>{ showOptions(focusItem); setFocusItem(false); rowAction("delete",{'deathrecordid':focusItem.deathrecordid,'slug':slug},"Remocing Obituary"); }}>Remove</button>
        </center>
      </div>

    </Popup>

    <Popup
      hide={()=>setShowForm(false)}
      show={showForm}
    >
      <Form
        initialData={optionItem}
        editKey="deathrecordid"
        fields={[
          {name:'title',label:'Name of Deceased',type:'text',required:true},
          {name:'details',label:'Obituary',type:'textarea',required:true},
          {name:'birthdate',label:'Birthday',type:'date',required:true},
          {name:'deathdate',label:'Date of Death',type:'date',required:true},

        ]}
      />
    </Popup>

    <List
      title={title}
      limit={limit}
      updateData={updateData}
      urlGET={`${config.obituaries.API_DOMAIN}api/${type}?slug=${slug}&order=pubdate&limit=${limit}`}
      template={(i,index)=>{
        return (
          <tr key={`item${index}`}>
            <th onClick={()=>setFocusItem(i)} style={{textDirection:"underline"}}>{i.title}</th>
            <td>{helpers.date(i.birthdate)} - {helpers.date(i.deathdate)}</td>
            <td>{i.slug}</td>
            <td style={{minWidth:"50px"}}>
              <div className="options" onClick={()=>showOptions(i)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Ellipsis Vertical Circle</title><circle fill="currentColor"  cx="256" cy="256" r="26"/><circle fill="currentColor" cx="256" cy="346" r="26"/><circle cx="256" cy="166" fill="currentColor"  r="26"/><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/></svg>
              </div>
            </td>
          </tr>
        )
      }}
    />
  </>);
}
