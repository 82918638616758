import React from "react";
import {userContext} from "../userContext";
import "../style/List.scss";


export default function List(props){
  const {urlGET,urlPOST,urlUPDATE,template,title,limit,filter,emptyText,setCount,noPaginate} = props;
  const [loading,setLoading] = React.useState(false);
  const [data,setData] = React.useState([]);
  const [nextPage,setNextPage] = React.useState(false);
  const [previousPage,setPreviousPage] = React.useState(false);
  const pageCursors = React.useRef([""]).current;
  const [pageIndex,setPageIndex] = React.useState(0);
  const {user} = React.useContext(userContext);

  React.useEffect(()=>{
    loadData();
  },[pageIndex]);

  React.useEffect(()=>{
    if(!props.updateData) return;
    if(props.updateData.key && props.updateData.keyValue){
      if(props.updateData.newData == "delete"){
        var newData = data.filter(d=>{

          if(d[props.updateData.key] == props.updateData.keyValue){
            return false
          }
          return true;
        });
      }
      else{
        var newData = data.map(d=>{

          if(d[props.updateData.key] == props.updateData.keyValue){
            return {...d,...props.updateData.newData};
          }
          return d;
        });
      }

      setData(newData);
    }
  },[props.updateData]);



  const loadData = (page) => {
    setData([]);
    setLoading(true);

    let dataURL = urlGET || urlPOST;
    let fetchData = {};
    if(dataURL.indexOf("?") > 0){
      dataURL += "&";
    }
    else{
      dataURL += "?";
    }
    dataURL += "id="+user.id+"&token="+encodeURIComponent(user.token)+"&limit="+(limit||20)+"&";


    dataURL += "page="+pageCursors[pageIndex];

    if(urlPOST){
      var formData = new FormData();
      formData.append('id',user.id);
      formData.append('token',user.token);
      fetchData = {
        method:"POST",
        body:formData
      };
    }


    if(filter){
      Object.keys(filter).forEach(k=>{
        dataURL += "&"+k+"="+filter[k];
      });
    }

    fetch(dataURL,fetchData)
    .then(res=>res.json())
    .then(data=>{
      if(data.error){
        setLoading(false);
        return;
      }
      if(noPaginate){
        setData(data);
        if(setCount){
          setCount(data.length);
        }
      }
      else{
        setData(data.data);
        if(setCount){
          setCount(data.data.length);
        }

        if(pageIndex == (pageCursors.length-1)){
          pageCursors.push(data.nextPageCursor);
        }

        setNextPage(data.nextPageCursor);
      }

      setLoading(false);
    });
  };



  const goNext = (e) => {
    e.preventDefault();
    setPageIndex(pageIndex+1);
  };

  const goPrevious = (e) => {
    e.preventDefault();
    setPageIndex(pageIndex-1);
  };

  const dataComponents = data.map((d,index)=>template(d,index));

  return (<div className="List">
    <h3>{title}</h3>

    <div className="tableHolder">
      {loading ? (<div className="loader">LOADING DATA...</div>) : (data.length == 0 && emptyText) && (
        <div className="emptyText">{emptyText}</div>
      )}

    <table>
      <tbody>
        {dataComponents}
      </tbody>
    </table>
    </div>
    {pageIndex > 0 && <button className="previousButton" onClick={goPrevious}>Previous Page</button>}
    {(nextPage && data.length == limit) && <button className="nextButton" onClick={goNext}>Next Page</button>}
    <div style={{clear:"both"}}></div>
  </div>);
}
