import React from "react";
import JobListingsList from "./JobListingsList";
import "../../style/BusinessDirectory.scss";

export default function JobListings(){
  return (<div className="BusinessDirectory">
      <h2>Job Listings</h2>
      <JobListingsList title="Your Job Listings" limit={30}/>
    </div>);
}
