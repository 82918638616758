import React from "react";
import ObituaryList from "./Obituaries/ObituaryList";
import CommentsList from "./Comments/CommentsList";
import BusinessDirectoryList from "./BusinessDirectory/BusinessDirectoryList";
import {NavLink} from "react-router-dom";

import {userContext} from "../userContext";


export default function Home(){
  const {user,userHash} = React.useContext(userContext);
  return (<>
    <big className="intro">Welcome to the Hub Hawaii Data Control Center.   This is where you can manage your profile and all postings made from your account.</big>

    <NavLink to="/comments" className="sectionParagraph">
      <b>My Comments <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Chevron Forward</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg></b>
      All comments you have posted on MauiNow.com, BigIslandNow.com and KauaiNowNews.com.
    </NavLink>

    <NavLink to="/jobs" className="sectionParagraph">
      <b>Job Listings <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Chevron Forward</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg></b>
      All job postings you have made to MauiNow.com, BigIslandNow.com and KauaiNowNews.com.
    </NavLink>

    <NavLink to="/directory" className="sectionParagraph">
      <b>Business Listings <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Chevron Forward</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg></b>
      Business profiles you have posted to the MauiNow.com, BigIslandNow.com and KauaiNowNews.com business directories (a.k.a. "What's Open").
    </NavLink>

    <a target="_blank" href="#" onClick={()=>{ window.open("https://hubhawaii.com/events/my/"+userHash()); }} className="sectionParagraph">
      <b>Event Listings <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Chevron Forward</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg></b>
      All event listings you have posted to the MauiNow.com, BigIslandNow.com and KauaiNowNews.com event calendars.
    </a>

    <a href="#" className="sectionParagraph" onClick={()=>window.hh2020Profile.showProfile(user.id)}>
      <b>My Profile <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Chevron Forward</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"/></svg></b>
      Update your name, username, e-mail address, profile picture and password from this section.
    </a>

    <p className="divider">
      <NavLink to="/delete" className="buttonText red" style={{textDecoration:"none",marginLeft:"auto",marginRight:"auto"}}>Delete My Account</NavLink>
    </p>

  </>);
}
