import React from "react";
import {userContext} from "./userContext";
import {BrowserRouter as Router, Routes, Route,useLocation} from "react-router-dom";
import Home from "./screens/Home";
import Comments from "./screens/Comments";
import BusinessDirectory from "./screens/BusinessDirectory";
import JobListings from "./screens/JobListings";
import DeleteAccount from "./screens/DeleteAccount";
import Obituaries from "./screens/Obituaries";
import Menu from "./screens/Menu";
import UserPopup from "./shared/UserPopup";


export default function DataRoutes(props){
  const {user,logout} = React.useContext(userContext);
  const location = useLocation();
  const [userid,setUserid] = React.useState(false);

  React.useEffect(()=>{
    var menu = document.querySelector("ul.menu");
    if(menu){
      menu.classList.remove("show");
      document.querySelector(".menuToggle").classList.remove("close");
    }
  },[location]);

  return (<>
    <UserPopup id={userid} hide={()=>setUserid(false)}/>

    <div className="holder">
        <Menu/>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/comments" element={<Comments/>}/>
          <Route path="/obituaries" element={<Obituaries/>}/>
          <Route path="/jobs" element={<JobListings/>}/>
          <Route path="/directory" element={<BusinessDirectory/>}/>
          <Route path="/delete" element={<DeleteAccount/>}/>

        </Routes>

    </div>
  </>);
}
