import React from "react";
import "./style/Loader.scss";

export const loaderContext = React.createContext();

export function LoaderContextProvider({children}){
  const [loader,showLoader] = React.useState(false);



  return (<loaderContext.Provider value={{showLoader}}>
    {loader === false ? (<></>) : (
      <div className="loaderScreen">
        <div className="loaderHolder">
          {loader}
        </div>

      </div>
    )}
    {children}
  </loaderContext.Provider>)
}
