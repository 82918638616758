import React from "react";
import UserBlock from "../shared/UserBlock";
import "../style/header.scss";

export default function Header(){
  const toggleMenu = (e) => {
    var menu = document.querySelector("ul.menu");

    if(menu){
      if(menu.classList.contains("show")){
        menu.classList.remove("show");
        e.currentTarget.classList.remove("close");
      }
      else{
        menu.classList.add("show");
        e.currentTarget.classList.add("close");
      }
    }
  }
  return (
      <div className="header">
        <div className="container">
          <div className="menuToggle" onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon open" viewBox="0 0 512 512"><title>Menu</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M80 160h352M80 256h352M80 352h352"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon close" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"/></svg>
          </div>
          <div className="headerLogo">
            <img src="https://hubhawaii.com/static/logo-black.png" className="logo"/>
            <h1>My Data</h1>
          </div>
          <UserBlock/>
        </div>
      </div>
    );
}
