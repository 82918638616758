import React from "react";
import "../style/Popup.scss";


export default function Popup(props){
  const {children,show,hide,disableUnFocus,buttons,style} = props;

  const buttonComponents = buttons && buttons.map(b=>{
    return (<button className={b.className} onClick={b.onClick}>{b.label}</button>);
  });

  return (
  <div className={`Popup ${show && `show`}`} onClick={(e)=>{ if(e.target == e.currentTarget){ hide();  }}}>
    <div className="popupHolder">
      <button className="close" onClick={(e)=>{ e.preventDefault(); hide(); }}>&times;</button>
      <div className="body" style={style}>
        {children}
      </div>
      {buttons ? (<div className="buttonHolder">
          {buttonComponents}
        </div>) : (<></>)}
    </div>
  </div>
  )

}
