import React from "react";
import List from "../../shared/List";
import UserPopup from "../../shared/UserPopup";
import {loaderContext} from "../../loaderContext";

import Popup from "../../shared/Popup";
import helpers from "../../shared/helpers";
import config from "../../config";
import {userContext} from "../../userContext";
import "../../style/Comments.scss";


export default function CommentsList({title,limit,filter,setCount}){

  const [showPopup,setShowPopup] = React.useState(false);
  const [comment,setComment] = React.useState(false);
  const [actionLoading,setActionLoading] = React.useState(false);
  const [actionSuccess,setActionSuccess] = React.useState(false);
  const [userID,setUserID] = React.useState(false);
  const {user} = React.useContext(userContext);
  const [updateData,setUpdateData] = React.useState([]);
  const {showLoader} = React.useContext(loaderContext);

  const showOptions = (focusComment) => {
    setComment(focusComment);
    setShowPopup(true);
  };

  const hidePopup =()=>{
    setActionLoading(false);
    setActionSuccess(false);
    setShowPopup(false);
    setComment(false);
  }

  const commentAction = (action,data,loading) => {
    setActionLoading(loading);
    var formData = new FormData;
    formData.append('id',user.id);
    formData.append('token',user.token);

    Object.keys(data).forEach(k=>{
      formData.append(k,data[k]);
    });
    showLoader("Deleting Comment");
    fetch(config.comments.API_DOMAIN+"api/comments/admin/"+action,{
      method:"POST",
      headers:{
        'Accept':'application/json'
      },
      body:formData
    }).then(res=>res.json())
    .then(rdata=>{
      setActionSuccess(rdata.message);
      showLoader(false);
      if(action == "hide"){
        setUpdateData({
          key:'id',
          keyValue:data.commentid,
          newData:{hidden:true}
        });
      }
      else if(action == "unhide"){
        setUpdateData({
          key:'id',
          keyValue:data.commentid,
          newData:{hidden:false}
        });
      }
      else if(action == "delete"){
        setUpdateData({
          key:'id',
          keyValue:data.commentid,
          newData:"delete"
        });
      }
      else if(action == "silence"){
        setUpdateData({
          key:'authorid',
          keyValue:data.userid,
          newData:{hidden:true,user_silence:true}
        });

      }
      else if(action == "unsilence"){
        setUpdateData({
          key:'authorid',
          keyValue:data.userid,
          newData:{user_silence:false}
        });

      }
      else if(action == "banuser"){
        setUpdateData({
          key:'authorid',
          keyValue:data.userid,
          newData:{user_ban:true,hidden:true}
        });

      }
      else if(action == "unbanuser"){
        setUpdateData({
          key:'authorid',
          keyValue:data.userid,
          newData:{user_ban:false}
        });

      }
    }).catch(e=>{
      hidePopup();
    });
  };


  return (<div className="Comments">
    <UserPopup id={userID} hide={()=>setUserID(false)}/>
    <Popup
     show={showPopup}
     hide={hidePopup}>
     {actionLoading ?
       (<>
         {actionSuccess ? (<>
           <center>{actionSuccess}</center>
           <ul className="optionsList">
            <li onClick={hidePopup}>OK</li>
           </ul>
         </>) : (<center>{actionLoading}</center>)}
       </>) : (
        <ul className="optionsList">
          <li onClick={()=>commentAction('delete',{commentid:comment.id},'Deleting Comment')}>Delete Comment</li>
          <li onClick={hidePopup} className="cancel">Cancel</li>
         </ul>
       )}
    </Popup>
    <List
      title={title}
      limit={limit}
      filter={filter}
      emptyText="You have not posted any comments"
      setCount={setCount}
      updateData={updateData}
      urlGET={`${config.comments.API_DOMAIN}api/comments/admin/list`}
      template={(i,index)=>{
        return (
          <tr key={`comment${index}`}>
            <td>
              <div className={`comment ${i.hidden && `hidden`} ${i.flags > 0 && `hasFlags`}`}>
                <div className="options"  onClick={()=>commentAction('delete',{commentid:i.id},'Deleting Comment')}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Trash</title><path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/><path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M80 112h352"/><path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/></svg>
                </div>
                <div className="commentUser">
                  <img onClick={()=>setUserID(i.authorid)} src={i.picture}/>
                  <b>{i.timeadded}</b>
                  <div className="user">
                    Posted by <b>{i.author}</b> ({i.username})
                  </div>
                  <a href={i.topicurl} target="_blank" className="topic">{i.topic}</a>
                </div>
                <div className="comment" dangerouslySetInnerHTML={{ __html: i.content.replace(/\\r/g,"") }}></div>
                <div className="stats">
                  <div className="likes">{i.likeCount} Like{(i.likeCount == 0 || i.likeCount > 1) && `s`}</div>
                  <div className="dislikes">{i.dislikeCount} Dislike{(i.dislikeCount == 0 || i.dislikeCount > 1) && `s`}</div>

                </div>

              </div>
            </td>
          </tr>
        )
      }}
    />
  </div>);
}
