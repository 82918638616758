import React from "react";
import List from "../../shared/List";
import UserPopup from "../../shared/UserPopup";
import "../../style/BusinessDirectory.scss";
import Popup from "../../shared/Popup";
import helpers from "../../shared/helpers";
import config from "../../config";
import {userContext} from "../../userContext";
import {ajaxGET,ajaxPOST} from "../../ajax";

export default function BusinessDirectoryList({title,limit,filter}){

  const [showPopup,setShowPopup] = React.useState(false);
  const [row,setRow] = React.useState(false);
  const [actionLoading,setActionLoading] = React.useState(false);
  const [actionSuccess,setActionSuccess] = React.useState(false);
  const [userID,setUserID] = React.useState(false);
  const {user,userHash} = React.useContext(userContext);
  const [updateData,setUpdateData] = React.useState([]);
  const [focusBusiness,setFocusBusiness] = React.useState(false);


  const editListing = (i) => {
    if(i.subregion.region.regionslug == "maui"){
      window.open("https://mauinow.com/whats-open/my-listings/"+userHash());
    }
    else if(i.subregion.region.regionslug == "big-island"){
      window.open("https://bigislandnow.com/whats-open/my-listings/"+userHash());
    }
    else if(i.subregion.region.regionslug == "kauai"){
      window.open("https://kauainownews.com/whats-open/my-listings/"+userHash());
    }
    else{
      window.open("https://mauinow.com/whats-open/my-listings/"+userHash());
    }
  }



  const showOptions = (focusRow) => {
    setRow(focusRow);
    setShowPopup(true);
  };

  const hidePopup =()=>{
    setActionLoading(false);
    setActionSuccess(false);
    setShowPopup(false);
    setRow(false);
  }

  const rowAction = async (action,data,loading) => {
    setActionLoading(loading);
    var formData = new FormData;

    var result = await ajaxPOST(config.businessdirectory.API_DOMAIN+"api/directory/v2/"+action,data);

    setActionSuccess(result.message);
    if(action == "delete" || action == "approve"){
      setUpdateData({
        key:'id',
        keyValue:data.businessid,
        newData:"delete"
      });
    }
    else if(action == "unhide"){
      setUpdateData({
        key:'id',
        keyValue:data.commentid,
        newData:{hidden:false}
      });
    }
  };


  return (<div className="BusinessDirectory">
    <Popup
      show={focusBusiness}
      hide={()=>setFocusBusiness(false)}
    >
      <div className="details">
        {focusBusiness.image && (<img src={focusBusiness.image}/>)}
        <h2>{focusBusiness.title}</h2>
        {focusBusiness.address}
        <br/>{focusBusiness.email} &middot; {focusBusiness.phone}
        <p dangerouslySetInnerHTML={{__html:focusBusiness.description}}></p>
        {(focusBusiness.website && focusBusiness.website !== "http://") && (<p>Website: <a href={focusBusiness.website} target="_blank">{focusBusiness.website}</a></p>)}

        <div className="userBlock" onClick={()=>setUserID(focusBusiness.userid)}>
          <img src={focusBusiness.userpicture}/>
          <b>{focusBusiness.userfullname}</b>
          {focusBusiness.username}



        </div>
        <center>
          <button className="button2" onClick={()=>{ showOptions(focusBusiness); setFocusBusiness(false); rowAction("approve",{'businessid':focusBusiness.id},"Approving Business Listing"); }}>Approve</button>
          <button className="button2 red" onClick={()=>{ showOptions(focusBusiness); setFocusBusiness(false); rowAction("delete",{'businessid':focusBusiness.id},"Removing Business Listing")}}>Remove</button>
        </center>
      </div>

    </Popup>

    <Popup
     show={showPopup}
     hide={hidePopup}>
     {actionLoading ?
       (<>
         {actionSuccess ? (<>
           <center>{actionSuccess}</center>
           <ul className="optionsList">
            <li onClick={hidePopup}>OK</li>
           </ul>
         </>) : (<center>{actionLoading}</center>)}
       </>) : (
        <ul className="optionsList">
          <li onClick={()=>editListing(row)}>Edit Business Listing</li>
          <li onClick={()=>rowAction("delete",{'businessid':row.id},"Removing Listing")}>Remove Business Listing</li>
          <li onClick={hidePopup} className="cancel">Cancel</li>
         </ul>
       )}
    </Popup>
    <UserPopup id={userID} hide={()=>setUserID(false)}/>



    <List
      title={title}
      limit={limit}
      noPaginate={true}
      emptyText="You have not posted any Business Listings"
      filter={filter}
      updateData={updateData}
      urlGET={`${config.businessdirectory.API_ENDPOINT}`}
      template={(i,index)=>{
        return (
          <tr key={`listing${index}`}>
            <td onClick={()=>editListing(i)}>
              <b>{i.title}</b>

              <br/>{i.subregion.region.regionname} &middot; {i.category.categoryname}
            </td>
            <td style={{minWidth:"50px"}}>
              <div className="options" onClick={()=>editListing(i)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Arrow Forward Circle</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M262.62 336L342 256l-79.38-80M330.97 256H170"/><path d="M256 448c106 0 192-86 192-192S362 64 256 64 64 150 64 256s86 192 192 192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32"/></svg>
              </div>
            </td>
          </tr>
        )
      }}
    />


  </div>);
}
