const config = {
  obituaries:{
    API_DOMAIN:"https://obituaries.hubhawaii.com/"
  //  API_DOMAIN:"http://obituaries.localhost:8003/"
  },
  comments:{
//    API_DOMAIN:"http://localhost:8080/"
    API_DOMAIN:"https://hubhawaii.com/"
  },
  user:{
    //API_DOMAIN:"http://localhost:8000/"
    API_DOMAIN:"https://hubhawaii.com/"
  },
  businessdirectory:{
    API_ENDPOINT:"https://hubhawaii.com/api/directory/mylistings"
  },
  jobListings:{
    API_ENDPOINT:"https://api.hubhawaii.com/jobListings/user/listings"
  },
  deleteAccount:{
    //API_ENDOINT:"https://hubhawaii.com/api/deleteAccount/",
    API_ENDOINT:"https://hubhawaii.com/api/deleteAccount/"
  }
};

export default config;
