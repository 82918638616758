import React from "react";
import "../style/UserBlock.scss";

export default function UserBlock(){
  return (
    <div className="hubhawaii">
      <div className="hubHawaiiUser">
        <div className="hubHawaiiPhoto profilePicture"><img src="" className="hubHawaiiUser_picture" style={{width:50,height:50,borderRadius:25}}/></div>
        <span className="desktop-only">
          <div className="profileName">
            Aloha, <span className="hubHawaiiUser_name"></span>!
          </div>

          <a href="#" className="hubHawaiiPhoto">My Profile</a> |
          <a href="#" className="hubHawaiiLogout">Logout</a>
        </span>

      </div>
      <div className="hubHawaiiGuest">
        <img src="https://hubhawaii.com/static/default.jpg" style={{opacity:0.7}} className="profilePicture hubHawaiiLogin"/>
        <span className="desktop-only">
          <div className="profileName">Aloha, Guest!</div>
          <a href="#" className="hubHawaiiLogin">Login</a> | <a href="#" className="hubHawaiiRegister">Register</a>
        </span>
      </div>
    </div>
  );
}
