import React from "react";
import "../style/Popup.scss";
import config from "../config";
import {userContext} from "../userContext";
import CommentsList from "../screens/Comments/CommentsList";


export default function UserPopup(props){
  const {id,hide} = props;
  const [userid,setUserID] = React.useState(false);
  const [data,setData] = React.useState(false);
  const {user,logout} = React.useContext(userContext);

  React.useEffect(()=>{
    setData(false);
    setUserID(id);
  },[id]);

  const hidePopup = () => {
    setUserID(false);
    setData(false);
    hide();
  };

  React.useEffect(()=>{
    if(!userid) return;
    var formData = new FormData;
    formData.append('id',user.id);
    formData.append('token',user.token);
    formData.append('userid',userid);


    fetch(config.comments.API_DOMAIN+"api/user/admin/get",{
      method:"POST",
      headers:{
        'Accept':'application/json'
      },
      body:formData
    }).then(res=>res.json())
    .then(data=>{
      setData(data);
    });
  },[userid]);


  return (
  <div className={`Popup ${userid && `show`}`} onClick={(e)=>{ if(e.target == e.currentTarget){ hidePopup(); }}}>
    <div className="popupHolder">
      <div className="userPopup">
        {data ? (<>
          <img src={data.profile.picture}/>
          <b>{data.name}</b>
          <br/><i>{data.profile.username}</i>
          <p>
            {data.profile.email}
            {data.profile.phone && (<><br/>{data.profile.phone}</>)}
          </p>

          <p>
            {user.id == data.id && (<a href="#" className="logout" onClick={logout}>LOGOUT</a>)}
          </p>
        </>) : (<div className="userPopup">
        Loading User Data

          </div>)}
      </div>
    </div>
  </div>
  )

}
