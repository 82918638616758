import React from "react";
import "../style/footer.scss";

export default function Footer(){

  const userHash = () => {
    if(window.hh2020Login.loggedIn){
      return "#HH::"+btoa(JSON.stringify({'timestamp':new Date().getTime(),'action':'login','authenticated':true,...window.hh2020Login.userData}));
    }
    return "";

  };

  const linkClick = (e) => {
    e.preventDefault();
    var href = e.target.getAttribute("href");
    window.location = href+userHash();
  };


  return (
      <div className="footer">
        <div className="container">
          <a href="https://hubhawaii.com/privacy/" onClick={linkClick}>Privacy Policy</a> | <a href="https://hubhawaii.com/terms-of-service" onClick={linkClick}>Terms of Service</a> | <a href="mailto:support@hubhawaii.com">Support</a>
          <div className="copyright">
            Copyright &copy; {new Date().getFullYear()} <a href="https://hubhawaii.com" onClick={linkClick}>HubHawaii.com</a> &middot; All Rights Reserved
          </div>
        </div>
      </div>
    );
}
