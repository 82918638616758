import React from "react";
import BusinessDirectoryList from "./BusinessDirectoryList";
import "../../style/BusinessDirectory.scss";

export default function Comments(){
  return (<div className="BusinessDirectory">
      <h2>Business Directory</h2>
      <BusinessDirectoryList title="Your Business Listings" limit={30}/>
    </div>);
}
